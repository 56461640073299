import { j as l } from "./jsx-runtime-B6kdoens.js";
import { _ as p } from "./index-28E3tTLh.js";
import * as $ from "react";
import { forwardRef as m, createElement as u } from "react";
import { $ as v } from "./index-CZBInFmv.js";
import { c as x } from "./utils-CJ9afRe1.js";
const i = "horizontal", h = [
  "horizontal",
  "vertical"
], c = /* @__PURE__ */ m((r, o) => {
  const { decorative: t, orientation: e = i, ...a } = r, n = f(e) ? e : i, d = t ? {
    role: "none"
  } : {
    "aria-orientation": n === "vertical" ? n : void 0,
    role: "separator"
  };
  return /* @__PURE__ */ u(v.div, p({
    "data-orientation": n
  }, d, a, {
    ref: o
  }));
});
c.propTypes = {
  orientation(r, o, t) {
    const e = r[o], a = String(e);
    return e && !f(e) ? new Error(O(a, t)) : null;
  }
};
function O(r, o) {
  return `Invalid prop \`orientation\` of value \`${r}\` supplied to \`${o}\`, expected one of:
  - horizontal
  - vertical

Defaulting to \`${i}\`.`;
}
function f(r) {
  return h.includes(r);
}
const s = c, E = $.forwardRef(
  ({ className: r, orientation: o = "horizontal", decorative: t = !0, ...e }, a) => /* @__PURE__ */ l.jsx(
    s,
    {
      ref: a,
      decorative: t,
      orientation: o,
      className: x(
        "shrink-0 bg-gray-neutral-80",
        o === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]",
        r
      ),
      ...e
    }
  )
);
E.displayName = s.displayName;
export {
  E as S
};
