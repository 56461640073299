import { j as t } from "./jsx-runtime-B6kdoens.js";
import { useState as v, useEffect as y, useRef as k, useLayoutEffect as C } from "react";
import "./Accordion-B6McINfk.js";
import "./Avatar-C7XsTcvJ.js";
import "./Badge-C0bj4DFU.js";
import { B as p } from "./Button-BrPm3sL-.js";
import "./DropdownMenu-DcXXsN6j.js";
import "./Icon-C5a5ARPP.js";
import "./Input-vl7Xx2ol.js";
import "./Label-Bzu7sDuW.js";
import { T as d } from "./Text-Bn-rHqca.js";
import { T as c } from "./Tooltip-DfaQUwkm.js";
import { c as g } from "./utils-CJ9afRe1.js";
import "./Stack-DHVjAP0n.js";
import "./Switch-Dlb1qEPd.js";
import "./Toast-C4Hef5AO.js";
import "./Toggle-BBLW8KXH.js";
function j(e, { lines: l }) {
  const [i, r] = v(!0);
  return y(() => {
    const a = () => {
      if (!e.current) return;
      const o = {
        webkitLineClamp: e.current.style.webkitLineClamp,
        display: e.current.style.display,
        webkitBoxOrient: e.current.style.webkitBoxOrient,
        overflow: e.current.style.overflow
      };
      e.current.style.webkitLineClamp = l.toString(), e.current.style.display = "-webkit-box", e.current.style.webkitBoxOrient = "vertical", e.current.style.overflow = "hidden", r(e.current.clientHeight !== e.current.scrollHeight), e.current.style.webkitLineClamp = o.webkitLineClamp, e.current.style.display = o.display, e.current.style.webkitBoxOrient = o.webkitBoxOrient, e.current.style.overflow = o.overflow;
    }, n = f(a, 50);
    return a(), window.addEventListener("resize", n), () => window.removeEventListener("resize", n);
  }, [e]), i;
}
function f(e, l) {
  let i;
  return function(...r) {
    clearTimeout(i), i = setTimeout(() => {
      e(...r);
    }, l);
  };
}
const F = (e) => {
  const { lines: l, onViewMore: i, onClamped: r, onView: a, inlineViewMore: n, onOverflowTooltipClick: o, viewText: w = "View", viewMoreText: h = "View more", overflowTooltip: x, ...b } = e, u = k(null), s = j(u, { lines: e.lines });
  return C(() => {
    r == null || r(s);
  }, [s]), /* @__PURE__ */ t.jsxs(
    "div",
    {
      ...b,
      className: g(
        e.className,
        "relative"
      ),
      children: [
        /* @__PURE__ */ t.jsx(c.Provider, { children: /* @__PURE__ */ t.jsxs(c, { children: [
          /* @__PURE__ */ t.jsx(
            c.Trigger,
            {
              asChild: !0,
              children: /* @__PURE__ */ t.jsxs(
                "div",
                {
                  className: `line-clamp-${l} overflow-hidden`,
                  ...s && {
                    onClick: i
                  },
                  ref: u,
                  children: [
                    e.children,
                    !s && a && /* @__PURE__ */ t.jsx("div", { className: `${!n && "absolute bottom-0 right-0"}`, children: /* @__PURE__ */ t.jsx(
                      p,
                      {
                        onClick: (m) => {
                          m.stopPropagation(), a();
                        },
                        className: `${!n && "ml-1"} bg-white text-inherit`,
                        type: "button",
                        variant: "custom",
                        size: "custom",
                        children: /* @__PURE__ */ t.jsx(
                          d,
                          {
                            as: "span",
                            variant: "blue",
                            weight: "normal",
                            underline: !0,
                            size: "inherit",
                            children: w
                          }
                        )
                      }
                    ) })
                  ]
                }
              )
            }
          ),
          s && /* @__PURE__ */ t.jsx(
            c.Content,
            {
              align: "start",
              side: "bottom",
              onClick: o,
              className: "text-xs font-normal underline text-blue-100",
              alignOffset: 0,
              children: x
            }
          )
        ] }) }),
        s && i && /* @__PURE__ */ t.jsx("div", { className: `${n ? "" : "absolute bottom-0 right-0"}`, children: /* @__PURE__ */ t.jsx(
          p,
          {
            onClick: (m) => {
              m.stopPropagation(), i();
            },
            className: " bg-white text-inherit",
            type: "button",
            variant: "custom",
            size: "custom",
            children: /* @__PURE__ */ t.jsx(
              d,
              {
                as: "span",
                variant: "blue",
                weight: "normal",
                underline: !0,
                size: "inherit",
                children: h
              }
            )
          }
        ) })
      ]
    }
  );
};
export {
  F as default
};
