import { j as e } from "./jsx-runtime-B6kdoens.js";
import { useState as i } from "react";
import "./Accordion-B6McINfk.js";
import "./Avatar-C7XsTcvJ.js";
import "./Badge-C0bj4DFU.js";
import "./Button-BrPm3sL-.js";
import "./DropdownMenu-DcXXsN6j.js";
import "./Icon-C5a5ARPP.js";
import { I as s } from "./Input-vl7Xx2ol.js";
import "./Label-Bzu7sDuW.js";
import { T as l } from "./Text-Bn-rHqca.js";
import "./Tooltip-DfaQUwkm.js";
import "./Stack-DHVjAP0n.js";
import "./Switch-Dlb1qEPd.js";
import "./Toast-C4Hef5AO.js";
import "./Toggle-BBLW8KXH.js";
import { A as o } from "./AlertDialog.component-B2rD9Sy3.js";
import { S as m } from "./Separator.component-BGgOPxab.js";
const D = (a) => {
  const [t, r] = i("");
  return /* @__PURE__ */ e.jsx(
    o,
    {
      onOpenChange: a.onOpenChange,
      open: a.open,
      children: /* @__PURE__ */ e.jsxs(
        o.Content,
        {
          "aria-label": "Rename",
          className: "h-auto max-w-xs rounded-md z-full-screen",
          children: [
            /* @__PURE__ */ e.jsx(l, { className: "px-3 pt-3", children: "Rename" }),
            /* @__PURE__ */ e.jsx(m, {}),
            /* @__PURE__ */ e.jsx("div", { className: "px-3", children: /* @__PURE__ */ e.jsx(
              s,
              {
                autoFocus: !0,
                "aria-label": "Rename",
                name: "name",
                onChange: (n) => r(n.target.value),
                placeholder: "New name...",
                defaultValue: a.name
              }
            ) }),
            /* @__PURE__ */ e.jsx(m, {}),
            /* @__PURE__ */ e.jsxs(o.Footer, { children: [
              /* @__PURE__ */ e.jsx(
                o.Cancel,
                {
                  autoFocus: !1,
                  children: "Cancel"
                }
              ),
              /* @__PURE__ */ e.jsx(
                o.Action,
                {
                  onClick: () => a.onRename(t),
                  disabled: !t || a.name === t,
                  children: "Save"
                }
              )
            ] })
          ]
        }
      )
    }
  );
};
export {
  D as R
};
